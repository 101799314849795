import axios from 'axios';

const axiosInstance = axios.create({baseURL: "https://api.techbuildme.com"})
const currentLang = localStorage.getItem('i18lngtechbuild') ? localStorage.getItem("i18lngtechbuild") : "en"


const getProjects = () => {
    return axiosInstance.get(`/projects/all?lang=${currentLang}`);
}

const getProject = (project_id) => {
    return axiosInstance.get(`/projects/${project_id}?lang=${currentLang}`)
}

const getVideo = () => {
    return axiosInstance.get("/main_video/all");
}

const sendEmail = (form) => {
    return axiosInstance.post("/follow_us/follow", form)
}

const sendMessage = (form) => {
    return axiosInstance.post("/lets_talk/send-email", form)
}

export {
    getProjects,
    getProject,
    getVideo,
    sendMessage,
    sendEmail
}