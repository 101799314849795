import LightModeIcon from '@mui/icons-material/LightMode';
import "./Switch.css";

export const Switch = () => {

    let darkMode = localStorage.getItem("darkMode");

    const enabled = () => {
        document.body.classList.add("dark");
        localStorage.setItem("darkMode", "enabled");
    }

    const disabled = () => {
        document.body.classList.remove("dark");
        localStorage.setItem("darkMode", null);
    }

    if(darkMode === "enabled"){
        enabled();
    }

    const switchTheme = () => {
        darkMode = localStorage.getItem("darkMode");

        if(darkMode !== "enabled") {
            enabled();
        } else {
            disabled();
        }
    }

    return (
            <button type='button' onClick={switchTheme}>
                <LightModeIcon style={{fill: "var(--Switch)"}}/>
            </button>
    )
}