import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";

// pages
import { Home } from './pages/Home';
import { Products } from './pages/Products';
import ProductDetail from './pages/ProductDetail';


function App() {
  useEffect(() => {
    AOS.init({duration: 1000});
  }, [])

  return (
      <Router>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/projects" element={<Products/>}/>
          <Route path="/projects/:id" element={<ProductDetail/>}/>
        </Routes>
      </Router>
  )
}

export default App;
