import { Navbar } from "../common/Navbar/Navbar"
import { Footer } from "../common/Footer/Footer"
import { useParams } from "react-router-dom"
import { useState, useEffect } from "react";
import { getProject } from "../utils/api";
import { useRecoilState } from 'recoil';
import { baseUrlState } from "../utils/atoms";

const ProductDetail = () => {
    const { id } = useParams();
    const [ project, setProject ] = useState({});
    const [ baseUrl ] = useRecoilState(baseUrlState);
    
    useEffect(() => {
        const getData = async () => {
            const response = await getProject(id);
            setProject(response?.data);
        }
        getData();
    }, [id])

    return (
    <>
        <Navbar/>
        <div className="container">
            <div className="product__container">
                <div className="product__title">
                    <h2>{project.title_highlight}</h2>
                    <h2>{project.title_head}</h2>
                    <p>{project.description}</p>
                    <div className="product__videos">
                        {
                            project?.project?.project_videos.map(data => {
                                const { id, video } = data
                                return <video className="product__videos" key={id} controls><source src={baseUrl + video} type="video/mp4"/></video>
                            })
                        }
                    </div>
                </div>
                <div className="product__images">
                    {
                        project?.project?.project_images.map(images => {
                            const { id, image } = images;
                            return <img key={id} src={baseUrl + image} alt="img"/>
                        })
                    }    
                </div>
            </div>
        </div>
        <Footer/>
    </>
  )
}

export default ProductDetail