import { useEffect } from 'react'
import { getProjects } from '../api'
import { useState } from 'react';

const useProjects = () => {
    const [ projects, setProjects ] = useState();

    const getData = async (setFunc) => {
        const response = await getProjects()
        setFunc(response.data)
    }

    useEffect(() => {
        getData(setProjects);
    }, [])

    return [projects]
}

export default useProjects