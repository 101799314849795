import { Project } from './Project';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import useProjects from '../../utils/hooks/useProjects';
import { useRecoilState } from 'recoil';
import { baseUrlState } from '../../utils/atoms/index';
import { useTranslation } from 'react-i18next';

export const Projects = () => {
    const { t } = useTranslation();
    const [ projects ] = useProjects([])
    const [ baseUrl ] = useRecoilState(baseUrlState);

    const renderProjects = useMemo(() => {
        return projects && projects.slice(0,3).map((project) => {
            return (
                <Project 
                    id={project.project.id}
                    key={project.project.id} 
                    head={project.title_head}
                    highlight={project.title_highlight} 
                    image={baseUrl + `${project.project.multimedia}`}/>
            )
        })
    }, [projects, baseUrl])

    
        return (
            <section className="container animation" data-aos="fade-up">
                <div className="products">
                   {renderProjects}
                    { 
                    projects?.length > 3 && 
                        <div className="show_products">
                            <button className="show_btn">
                                <Link to="/projects">{t("products.showProducts")}</Link>
                            </button>
                        </div>
                    }
                </div>
            </section>
        )
}