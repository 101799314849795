import "./Product.css";
import { Link } from 'react-router-dom';

export const Project = ({id, head, highlight, image}) => {
    return (
        <Link to={`/projects/${id}`} className="figure">
            <figcaption>{head}</figcaption>
            <figcaption>{highlight}</figcaption>
            <img src={image} alt="project_image"/>
        </Link>
    )
}