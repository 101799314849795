export const NavbarData = [
    {
        path: "/",
        name: "home",
    },
    {
        path: "/projects",
        name: "projects"
    },
    {
        path: "/#about",
        name: "about"
    },
    {
        path: "/#contact",
        name: "contact"
    }
]