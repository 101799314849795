import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendMessage } from '../../utils/api';

export const ContactForm = ({pop, setPop}) => {
    const { t } = useTranslation();
    const [ name, setName ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ phone, setPhone ] = useState("");
    const [ message, setMessage ] = useState("");

    const handleSubmit = async () => {
        await sendMessage(new URLSearchParams({
            "full_name": name,
            "phone": phone,
            "email": email,
            "message": message
        }))
    }

    return (
        <div className="contact_form__container">
            <div className="contact_form animation" data-aos="zoom-in">
                <div className="contact_form__heading">
                    <h2>{t("contact-form.heading")}</h2>
                    <CloseRoundedIcon fontSize="35" onClick={() => setPop(!pop)}/>
                </div>
                <form className="contact_form__body" onSubmit={handleSubmit}>
                        <input 
                            type="text" value={name}
                            onChange={e => setName(e.target.value)}
                            autoComplete="off" required
                            placeholder={t("contact-form.name")}/>
                        <div className="contact_form__item">
                            <input 
                                type="email" value={email}
                                onChange={e => setEmail(e.target.value)}
                                autoComplete="off" required
                                placeholder={t("contact-form.email")}/>
                            <input 
                                type="tel" value={phone}
                                onChange={e => setPhone(e.target.value)}
                                autoComplete="off" required
                                placeholder={t("contact-form.number")}/>
                        </div>
                        <textarea 
                            name="message" 
                            onChange={e => setMessage(e.target.value)}
                            rows="10"
                            defaultValue={message}
                            maxLength="500"
                            placeholder='Type message...'></textarea>
                        <button type="submit">{t("contact-form.send")}</button>
                </form>
            </div>
        </div>
    )
}