import "./Navbar.css";
import { Switch } from '../../components/Switch/Switch';
import Language from "../../components/Language/Language";
import { useState } from 'react';
import { NavbarData } from "../../assets/NavData";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

export const Navbar = () => {
    const [ open, setOpen ] = useState(false);
    const nav = useNavigate();

    return (
        <>
        <nav id="nav">
            <div className="logo" onClick={() => nav("/")}></div>
            <div className="nav__list">
                <span>+993 65 55 77 88</span>
                <span>info@techbuildme.com</span>
                
            </div>
            <div className="options">
                <Language/>
                <div className="menu">
                    <Switch/>
                    <div 
                    className={open ? "burger is_active" : "burger"}
                    onClick={() => setOpen(!open)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>    
                </div>
            </div>
            
        </nav>
        {open && <DropDown open={open} setOpen={setOpen}/>}
        </>
    )
}

const DropDown = ({ open, setOpen }) => {
    const DropDownItem = (props) => {
        return (
            <>
                {props.children}
            </>
        )
    }
    return (
        <div className="dropdown" data-aos-duration="300" data-aos="fade-down">
            <DropDownItem>
                {
                    NavbarData.map((navbar, index) => {
                        const { path, name } = navbar;
                        return <a href={path} key={index} onClick={() => setOpen(!open)}>{t(`navbar.${name}`)}</a>
                    })
                }
            </DropDownItem>
        </div>
    )
}