import "./Services.css";

export const Services = () => {
    return (
        <section className="container animation" id="services" data-aos="fade-down">
        <div className="marquee">
            <span>how we works</span>
            <span>how we works</span>
            <span>how we works</span>
            <span>how we works</span>
            <span>how we works</span>
            <span>how we works</span>
            <span>how we works</span>
        </div>
        <div className="services">
            <div className="service">
                <div className="service__number">01</div>
                <div className="service__title">briefing your project</div>
                <div className="service__desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc scelerisque at malesuada gravida tortor. Elit nulla tempus sed velit pharetra nec.
                </div>
            </div>
            <div className="service">
                <div className="service__number">02</div>
                <div className="service__title">agreement of terms</div>
                <div className="service__desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc scelerisque at malesuada gravida tortor. Elit nulla tempus sed velit pharetra nec.
                </div>
            </div>
            <div className="service">
                <div className="service__number">03</div>
                <div className="service__title">purchase of materials</div>
                <div className="service__desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc scelerisque at malesuada gravida tortor. Elit nulla tempus sed velit pharetra nec.
                </div>
            </div>
            <div className="service">
                <div className="service__number">04</div>
                <div className="service__title">construction works</div>
                <div className="service__desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc scelerisque at malesuada gravida tortor. Elit nulla tempus sed velit pharetra nec.
                </div>
            </div>
        </div>
    </section>
    )
}