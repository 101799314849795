import { ContactForm } from "../ContactForm/ContactForm";
import { useState } from "react";
import "./Contact.css";
import { useTranslation } from "react-i18next";

export const Contact = () => {
    const [ pop, setPop ] = useState(false);
    const { t } = useTranslation();

    return (
        <>
        <section className="container contactForm animation" id="contact" data-aos="fade-down">
            <div className="contact__heading">{t("contacts.heading")}</div>
            <div className="contact">
                <div className="contact__item"> 
                    <h4>{t("contacts.address")}</h4>
                    <p>{t("contacts.address-info")}</p>
                </div>
                <div className="contact__item">
                    <h4>{t("contacts.phone")}</h4>
                    <ul>
                        <li>+971 50 801 2203</li>
                        <li>+971 58 119 1977</li>
                    </ul>
                </div>
                <div className="contact__item">
                    <h4>{t("contacts.email")}</h4>
                    <ul>
                        <li>info@techbuildme.com</li>
                        <li>marketing@techbuildme.com</li>
                    </ul>
                </div>
            </div>
            <button className="contact_btn" onClick={() =>{setPop(!pop)}}>{t("contacts.talk")} &rarr;</button>
        </section>
        {pop ? <ContactForm pop={pop} setPop={setPop}/> : null}
        </>
    )
}