import { useTranslation } from "react-i18next";
import "./Language.css"

const Language = () => {
    const { i18n } = useTranslation();

    const handleChangeLanguage = e => {
        const lang = e.target.value;
        i18n.changeLanguage(lang);
        localStorage.setItem("i18lngtechbuild", lang);
        window.location.reload(true);
    }

    return (
        <select value={i18n.language} onChange={handleChangeLanguage}>
            <option value="en">EN</option>
            <option value="tm">TM</option>
        </select>
    )
}

export default Language;