import "./Header.css";
import hero from '../../assets/hero.png';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Header = () => {
    const { t } = useTranslation();

    return (
        <header>
            <section className="hero">
                <div className="hero__content">
                    <div className="hero__title animation" data-aos="fade-right">
                        <h2>{t("header_title.title")}</h2>
                        <div className="title">
                            <span>{t("header_title.1")}</span>
                            <span>{t("header_title.2")}</span>
                        </div>
                    </div>
                    <div className="social_media animation" data-aos="zoom-in">
                        <Link to="instagram.com">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6 2C3.79086 2 2 3.79086 2 6V16C2 18.2091 3.79086 20 6 20H16C18.2091 20 20 18.2091 20 16V6C20 3.79086 18.2091 2 16 2H6ZM0 6C0 2.68629 2.68629 0 6 0H16C19.3137 0 22 2.68629 22 6V16C22 19.3137 19.3137 22 16 22H6C2.68629 22 0 19.3137 0 16V6Z" fill="#8DC838"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.4833 7.989C10.8591 7.89644 10.2216 8.00305 9.66151 8.29368C9.10141 8.5843 8.64721 9.04414 8.36351 9.60778C8.07982 10.1714 7.98107 10.8102 8.08132 11.4332C8.18157 12.0562 8.47571 12.6317 8.9219 13.0779C9.36809 13.5241 9.94361 13.8182 10.5666 13.9184C11.1896 14.0187 11.8283 13.9199 12.392 13.6363C12.9556 13.3526 13.4155 12.8984 13.7061 12.3383C13.9967 11.7782 14.1033 11.1407 14.0108 10.5165C13.9164 9.8798 13.6197 9.29036 13.1645 8.83523C12.7094 8.3801 12.12 8.08341 11.4833 7.989ZM8.74037 6.51843C9.67387 6.03406 10.7363 5.85637 11.7766 6.01063C12.8378 6.16799 13.8202 6.66246 14.5788 7.42101C15.3373 8.17957 15.8318 9.16198 15.9891 10.2231C16.1434 11.2634 15.9657 12.3259 15.4813 13.2594C14.997 14.1929 14.2306 14.9499 13.2912 15.4227C12.3518 15.8955 11.2872 16.0601 10.2489 15.893C9.21054 15.726 8.25134 15.2357 7.50768 14.4921C6.76403 13.7484 6.2738 12.7892 6.10672 11.7509C5.93964 10.7126 6.10422 9.648 6.57704 8.7086C7.04987 7.7692 7.80687 7.00281 8.74037 6.51843Z" fill="#8DC838"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.5 5.5C15.5 4.94772 15.9477 4.5 16.5 4.5H16.51C17.0623 4.5 17.51 4.94772 17.51 5.5C17.51 6.05228 17.0623 6.5 16.51 6.5H16.5C15.9477 6.5 15.5 6.05228 15.5 5.5Z" fill="#8DC838"/>
                            </svg>
                        </Link>
                        <Link to="twitter.com">
                            <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.4996 3.36158C12.3849 3.9424 11.6282 5.15834 11.9779 6.79021C12.0401 7.08046 11.9701 7.38335 11.7869 7.61691C11.6037 7.85048 11.3262 7.99058 11.0295 7.9993C7.8533 8.09272 4.68323 6.9073 2.33194 4.77767C1.17223 8.55813 3.34471 12.8591 7.28743 14.0419C7.63595 14.1465 7.90018 14.4321 7.97734 14.7877C8.0545 15.1433 7.93243 15.5127 7.65859 15.7523C6.72719 16.5673 5.6254 17.1668 4.44473 17.5422C11.6551 19.7455 19.3026 13.6199 18.0126 5.55772C17.962 5.24109 18.0662 4.91937 18.293 4.69263C18.4741 4.51147 18.6445 4.30755 18.8029 4.09208C18.5442 4.15576 18.2701 4.19973 18.0001 4.19973C17.7468 4.19973 17.5029 4.10362 17.3178 3.93079C16.1476 2.83867 14.6361 2.7695 13.4996 3.36158ZM21.0001 1.99973C21.9488 2.31596 21.9486 2.31633 21.9486 2.31633L21.9473 2.32027L21.9448 2.32764L21.9367 2.3513C21.9299 2.37095 21.9203 2.39827 21.9079 2.43253C21.8831 2.501 21.8472 2.59746 21.8003 2.7161C21.7066 2.95282 21.5679 3.28086 21.3853 3.65291C21.0842 4.2661 20.6424 5.04338 20.0565 5.72841C21.3643 16.3192 9.6486 23.9316 0.447624 17.8333C0.0728822 17.5849 -0.0900414 17.117 0.0494034 16.6896C0.188848 16.2622 0.596377 15.9804 1.04549 16.0008C2.40598 16.0626 3.75236 15.7811 4.91186 15.2013C0.550455 12.7951 -1.36741 7.10175 1.12146 2.52222C1.27908 2.23219 1.56968 2.03864 1.89805 2.00495C2.22641 1.97127 2.55025 2.1018 2.76347 2.35379C4.5321 4.44399 7.16427 5.75874 9.89112 5.97333C9.90172 3.98 11.0241 2.3962 12.5755 1.58788C14.2934 0.692859 16.5349 0.748311 18.3144 2.15253C18.4009 2.13156 18.5004 2.10284 18.6127 2.06489C18.8908 1.97089 19.1895 1.84041 19.4727 1.70167C19.7537 1.56406 20.0052 1.42535 20.1869 1.3207C20.2773 1.26864 20.3493 1.22564 20.3977 1.19626C20.4219 1.18159 20.4401 1.17037 20.4518 1.16316L20.4642 1.15546L20.4661 1.15425C20.8234 0.928673 21.2842 0.951942 21.6168 1.21258C21.9496 1.47327 22.0823 1.91535 21.9486 2.31633L21.0001 1.99973Z" fill="#8DC838"/>
                            </svg>
                        </Link>
                        <Link to="facebook.com">
                            <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M4.75736 1.75736C5.88258 0.632141 7.4087 0 9 0H12C12.5523 0 13 0.447715 13 1V5C13 5.55228 12.5523 6 12 6H9V8H12C12.3079 8 12.5987 8.14187 12.7882 8.38459C12.9777 8.6273 13.0448 8.94379 12.9701 9.24254L11.9701 13.2425C11.8589 13.6877 11.4589 14 11 14H9V21C9 21.5523 8.55228 22 8 22H4C3.44772 22 3 21.5523 3 21V14H1C0.447715 14 0 13.5523 0 13V9C0 8.44772 0.447715 8 1 8H3V6C3 4.4087 3.63214 2.88258 4.75736 1.75736ZM9 2C7.93913 2 6.92172 2.42143 6.17157 3.17157C5.42143 3.92172 5 4.93913 5 6V9C5 9.55229 4.55228 10 4 10H2V12H4C4.55228 12 5 12.4477 5 13V20H7V13C7 12.4477 7.44772 12 8 12H10.2192L10.7192 10H8C7.44772 10 7 9.55229 7 9V6C7 5.46957 7.21071 4.96086 7.58579 4.58579C7.96086 4.21071 8.46957 4 9 4H11V2H9Z" fill="#8DC838"/>
                            </svg>
                        </Link>
                    </div>
                </div>
                <div className="hero__img animation" data-aos="fade-left">
                    <img src={hero} alt="hero"/>
                </div>
            </section>
        </header>
    )
}