import { useState } from "react";
import { useTranslation } from "react-i18next";
import { sendEmail } from '../../utils/api';

const Follow = () => {
    const { t } = useTranslation();
    const [ email, setEmail ] = useState('');

    const handleSubmit = async () => {
        try {
            await sendEmail(new URLSearchParams({"email": email}))
            setEmail("");
        } catch(err) {
            console.log(err)
        }
    }

    return <div className="subscribtion">
        <div className="footer_heading">{t("follow.heading")}</div>
        <form className="follow_bar" onSubmit={(e) => {
            e.preventDefault();
            handleSubmit()}}>
            <input type="email" placeholder={t("follow.email")} value={email} onChange={e => setEmail(e.target.value)}/>
            <button type="submit">{t("follow.button")}</button>
        </form>
    </div>
}

export default Follow;