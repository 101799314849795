import { useRecoilState } from "recoil";
import useProjects from '../utils/hooks/useProjects';
import { baseUrlState } from "../utils/atoms/index";
import { Project } from "../components/Project/Project";
import { Navbar } from '../common/Navbar/Navbar';
import { Footer } from "../common/Footer/Footer";

export const Products = () => {
    const [ projects ] = useProjects([])
    const [ baseUrl ] = useRecoilState(baseUrlState);

    const resultsRender = [];

    for(var i = 0; i < projects?.length; i += 3) {
        resultsRender.push(
            <div className='products' key={i}>
                {
                    projects.slice(i, i + 3)
                    .map((project) => (
                        <Project
                            key={project.project.id}
                            id={project.project.id}
                            head={project.title_head}
                            highlight={project.title_highlight} 
                            image={baseUrl + `${project.project.multimedia}`}/>
                    ))
                }
            </div>
        )
    }

    return (
        <>
            <Navbar/>
            <div className='container'>
                {resultsRender}
            </div>
            <Footer/>
        </>
    )
}