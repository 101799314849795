import Follow from '../../components/Follow/Follow';
import "./Footer.css";
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
    const nav = useNavigate();
    const { t } = useTranslation();

    return (
        <footer>
            <div className="footer">
                <Follow/>
                <div className="footer__list">
                    <div className="item__list">
                        <h2>services</h2>
                        <ul>
                            <li><Link to="/">laser shows</Link></li>
                            <li><Link to="/">light shows</Link></li>
                        </ul>
                    </div>
                    <div className="item__list">
                        <h2>pages</h2>
                        <ul>
                            <li><a href="/#about">{t("navbar.about")}</a></li>
                            <li><a href="/projects">{t("navbar.projects")}</a></li>
                            <li><a href="/#services">{t("navbar.services")}</a></li>
                            <li><a href="/#contact">{t("navbar.contact")}</a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer_logo">
                    <div className="logo" onClick={() => nav("/")}></div>
                    <p>© 2022. Tekmil - All right reserved.</p>
                </div>
            </div>
        </footer>
    )
}