import { useTranslation } from "react-i18next";
import "./About.css";

export const About = () => {
    const { t } = useTranslation();

    return (
        <section className='container animation' id="about" data-aos="zoom-in">
            <div className="about">
                <div className="heading">{t("about.heading")}</div>
                <div className="description">
                    <span>{t("about.1")}</span> {t("about.2")} 
                    <span> {t("about.3")}</span> {t("about.4")} 
                    <span> {t("about.5")}</span> {t("about.6")} 
                    <span> {t("about.7")}</span> {t("about.8")} 
                    <span> {t("about.9")}</span> {t("about.10")} 
                    <span> {t("about.11")}</span>
                </div>
            </div>
        </section>
    )
}