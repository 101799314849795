import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationTM from "./locales/tm/translation.json";

const resources = {
    en: {
        translation: translationEN
    },
    tm: {
        translation: translationTM
    }
}

i18n
.use(initReactI18next)
.init({
    resources,
    lng: localStorage.getItem("i18lngtechbuild") ? localStorage.getItem("i18lngtechbuild") : "en", // default language
    interpolation: {
        escapeValue: false,
    }
})

export default i18n;