import { Navbar } from '../common/Navbar/Navbar';
import { Footer } from '../common/Footer/Footer';
import { Header } from '../common/Header/Header';
import { About } from '../components/About/About';
import { Services } from '../components/Services/Services';
import { Projects } from '../components/Project/Projects';
import { Contact } from '../components/Contact/Contact';

export const Home = () => {
    return (
        <>
            <Navbar/>
            <Header/>
            <About/>
            <Services/>
            <Projects/>
            <Contact/>
            <Footer/>
        </>
    )   
}